import React from "react";
import { config } from '../../consts';
import ReactGA from "react-ga4";

function LinkedinIconWhite() {
    const handleClick = () => {
        ReactGA.event({
            category: "button",
            action: "Social media click",
            label: "Linkedin White",
        });

        window.open(config.urls.linkedin, "_blank", "noopener,noreferrer");
    };
    
    return (
        <div onClick={handleClick} style={{ cursor: 'pointer' }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="36"
                fill="none"
                viewBox="0 0 35 36"
            >
                <path
                    fill="#F5F5F9"
                    d="M17.5.5C7.835.5 0 8.335 0 18s7.835 17.5 17.5 17.5S35 27.665 35 18 27.165.5 17.5.5zm-4.67 25.96H8.99V14.047h3.842V26.46zm-1.938-14.039a2.281 2.281 0 01-2.273-2.29 2.281 2.281 0 012.273-2.29 2.281 2.281 0 012.272 2.29 2.281 2.281 0 01-2.272 2.29zm16.338 14.04h-3.824v-6.517c0-1.787-.679-2.784-2.092-2.784-1.538 0-2.341 1.039-2.341 2.784v6.516h-3.685V14.047h3.685v1.672s1.108-2.05 3.74-2.05c2.633 0 4.517 1.607 4.517 4.931v7.86z"
                ></path>
            </svg>
        </div>
    );
}

export default LinkedinIconWhite;