import React, {forwardRef} from "react";
import styled from "styled-components";
import SkillCard from './components/SkillCard.component';
import web3Logo from "../../assets/Web3.png";
import typeScriptLogo from "../../assets/TypeScript.png";
import tonLogo from "../../assets/Ton.png";
import solidityLogo from "../../assets/Solidity.png";
import reactLogo from "../../assets/React.png";
import mySQlLogo from "../../assets/MySQL.png";

const skillsData = [
    { name: "Web3", image: web3Logo },
    { name: "TypeScript", image: typeScriptLogo },
    { name: "TON", image: tonLogo  },
    { name: "Solidity", image: solidityLogo },
    { name: "React", image: reactLogo },
    { name: "MySQL", image: mySQlLogo }
];

const SkillsSection = forwardRef((props, ref) => {
    return (
        <Section ref={ref}>
            <SkillsContainer>
                <SkillsIntro>
                    <SkillsTitle>My skills</SkillsTitle>
                    <SkillsDescription>
                        Combining Expertise in Solidity, React, and Web3 to Develop Cutting-Edge Solutions for Modern Enterprises
                    </SkillsDescription>
                </SkillsIntro>
                <SkillsGrid>
                    {skillsData.map((skill, index) => (
                        <SkillCard key={index} name={skill.name} image={skill.image} />
                    ))}
                </SkillsGrid>
            </SkillsContainer>
        </Section>
    );
});

const Section = styled.section`
    display: flex;
    align-items: center;
    flex-direction: column;
    //gap: 40px;
    box-sizing: border-box;
    color: var(--grey, #848b9a);
    letter-spacing: -0.32px;
    //justify-content: start;
    padding: 30px 10%;
    width: 100%;
    margin-top: 50px;

    @media (max-width: 1441px) {
        padding: 30px 3% 0 3%;
    }

    @media (max-width: 1280px) {
        padding: 20px 8% 0 8%;
    }
    
    @media (max-width: 991px) {
        flex-direction: column;
        padding: 20px;
        margin-top: 20px;
    }

    @media (max-width: 510px) {
        padding: 1%;
    }
`;

const SkillsContainer = styled.div`
  display: flex;
  margin-top: 40px;
  width: 100%;
  gap: 20px;
  justify-content: flex-start;
  flex-wrap: wrap;
    align-items: stretch;
    flex-grow: 1;
    height: 100%;
  
    @media (max-width: 991px) {
        max-width: 100%;
        margin-top: 40px;  
        gap: 10px;
    }

    @media (max-width: 799px) {
        flex-direction: column;
    }
`;

const SkillsIntro = styled.div`
    justify-content: space-between;
    border-radius: 40px;
    background-color: var(--soft-blue);
    align-self: stretch;
    display: flex;
    flex-direction: column;
    font-family: Manrope, sans-serif;
    color: var(--white);
    font-weight: 600;
    flex: 1;
    flex-basis: 0;
    margin: 0;
    padding: 46px 130px 46px 45px;

    @media (max-width: 1449px) {
        padding: 46px 100px 46px 45px;
    }

    @media (max-width: 1280px) {
        padding: 46px 20px 46px 45px;
    }

    @media (max-width: 1099px) {
        padding: 30px 20px 20px 45px;
    }

    @media (max-width: 799px) {
        min-height: 300px;
    }

    @media (max-width: 510px) {
        min-height: 400px;
        padding: 60px 20px 20px 45px;
    }

    @media (max-width: 419px) {
        padding: 60px 30px 20px 20px;
    }
`;

const SkillsTitle = styled.h2`
  font-size: 80px;
  line-height: 1;
  letter-spacing: -1.6px;
    margin-top: 10px;
    font-weight: 700;
    
  @media (max-width: 1099px) {
    max-width: 100%;
      font-size: 60px;
  }

    @media (max-width: 510px) {
        font-size: 50px;
        font-weight: 500;
    }

    @media (max-width: 419px) {
        font-size: 40px;
        font-weight: 0;
    }


    //@media (max-width: 1280px) {
    //    font-size: 60px;
    //}
`;

const SkillsDescription = styled.p`
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.4px;
  margin-top: auto;
    font-weight: 400;

    @media (max-width: 1280px) {
        margin-top: 50px;
    }
    
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 0;
  }
`;

const SkillsGrid = styled.div`
  align-self: stretch;
  display: flex;
  min-width: 240px;
  align-items: flex-start;
  gap: 20px;
   // height: 100%;
  color: var(--grey);
  letter-spacing: -0.4px;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 640px;
  margin: 0;
  font: 500 20px Inter, sans-serif;
    
    @media (max-width: 1280px) {
        gap: 10px;
        width: 500px;
    }

    @media (max-width: 1100px) {
        gap: 10px;
        width: 400px;
    }
    
    @media (max-width: 991px) {
        gap: 10px;
        width: 500px;
    }

    @media (max-width: 949px) {
        gap: 10px;
        width: 400px;
    }

    @media (max-width: 799px) {
        gap: 10px;
        width: 100%;
    }
`;

export default SkillsSection;