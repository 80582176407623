import React from "react";

function LinkedinIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <path
                fill="#5988FF"
                d="M20 0C8.954 0 0 8.954 0 20s8.954 20 20 20 20-8.954 20-20S31.046 0 20 0zm-5.336 29.67h-4.391V15.481h4.39V29.67zm-2.217-16.046a2.607 2.607 0 01-2.596-2.617 2.607 2.607 0 012.596-2.618 2.607 2.607 0 012.597 2.618 2.607 2.607 0 01-2.597 2.617zM31.12 29.67h-4.37v-7.447c0-2.042-.776-3.182-2.39-3.182-1.758 0-2.677 1.187-2.677 3.182v7.447h-4.211V15.482h4.211v1.911s1.267-2.343 4.275-2.343c3.009 0 5.162 1.836 5.162 5.636v8.983z"
            ></path>
        </svg>
    );
}

export default LinkedinIcon;