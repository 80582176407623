import React from 'react';
import styled from 'styled-components';
import ContactMeButtonHeader from "../../../ui/Buttons/ContactMeHeader.button.component";

const HeroBlock = ({scrollOnClick}) => {
    return (
        <HeroSection>
            <HeroTitle>Hello, my name is Aleksandr Titov</HeroTitle>
            <HeroDescription>
                Young and professional Middle+ blockchain developer creating innovative solutions for business
            </HeroDescription>
            <ButtonWrapper>
                <ContactMeButtonHeader scrollOnClick={scrollOnClick} />
            </ButtonWrapper>
        </HeroSection>
    );
};

const HeroSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 80px;
    max-width: 600px;
`;

const HeroTitle = styled.h1`
    color: #fff;
    letter-spacing: -3.2px;
    font: 600 64px/77px Inter, sans-serif;
    margin-bottom: 0;

    @media (max-width: 599px) {
        font: 600 50px/64px Inter, sans-serif;
        overflow: auto;
    }

    @media (max-width: 399px) {
        font: 600 40px/50px Inter, sans-serif;
    }
`;

const HeroDescription = styled.p`
    color: #848b9a;
    letter-spacing: -0.32px;
    margin-top: 10px;
    margin-bottom: 0;
    width: 408px;
    
    font: 400 16px/24px Inter, sans-serif;

    @media (max-width: 599px) {
        font: 400 15px/23px Inter, sans-serif;
        overflow: auto;
        width: 70%;
    }

    @media (max-width: 399px) {
        font: 400 13px/20px Inter, sans-serif;
        width: 80%;
    }
`;

const ButtonWrapper = styled.div`
  color: #848b9a;
  letter-spacing: -0.32px;
  margin-top: 31px;
  max-width: 186px;
  font: 400 16px/24px Inter, sans-serif;
`;

export default HeroBlock;