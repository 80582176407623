import styled from "styled-components";
import React from "react";
import SocialIcons from "./SocialIcons.component";
import ReactGA from "react-ga4";

const MenuOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 1);
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 990;
    transition: opacity 0.3s ease-in-out;
`;

const MenuContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    text-align: center;
`;

const MenuItem = styled.div`
    font-size: 48px;
    color: #fff;
    cursor: pointer;
    width: 100%;
    transition: color 0.2s;
    font-weight: 600;

    &:hover {
        color: #bbb;
    }
`;


const MobileMenu = ({ menuItems, scrollOnClick, toggleMenu, isOpen }) => {
    const handleClick = (item) => {
        ReactGA.event({
            category: "button",
            action: "Menu mobile click",
            label: item.label,
        });

        scrollOnClick(item.id);
        toggleMenu();
    }

    return (
        <MenuOverlay isOpen={isOpen} onClick={toggleMenu}>
            <MenuContent onClick={(e) => e.stopPropagation()}>
                {menuItems.map((item) => (
                    <MenuItem key={item.id} onClick={() => handleClick(item)}>
                        {item.label}
                    </MenuItem>
                ))}
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <SocialIcons />
                </div>

            </MenuContent>
        </MenuOverlay>
    );
};

export default MobileMenu;