import React from "react";
import { config } from '../../consts';
import ReactGA from "react-ga4";

function GitHubIconWhite() {
    const handleClick = () => {
        ReactGA.event({
            category: "button",
            action: "Social media click",
            label: `Github White`,
        });

        window.open(config.urls.github, "_blank", "noopener,noreferrer");
    };
    
    return (
        <div onClick={handleClick} style={{cursor: 'pointer'}}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="36"
                fill="none"
                viewBox="0 0 35 36"
            >
                <path
                    fill="#F5F5F9"
                    d="M17.5.5C13.345.5 9.325 2.016 6.16 4.777 2.995 7.538.892 11.364.226 15.57a18.323 18.323 0 002.3 12.162c2.152 3.645 5.498 6.387 9.44 7.735.875.157 1.203-.382 1.203-.853 0-.426-.022-1.839-.022-3.341-4.397.83-5.535-1.1-5.885-2.109A6.52 6.52 0 005.47 26.63c-.613-.337-1.488-1.166-.022-1.189.56.063 1.096.262 1.565.582.468.32.854.752 1.125 1.257.24.441.562.83.948 1.142.385.314.828.545 1.301.683a3.652 3.652 0 002.848-.344 3.814 3.814 0 011.116-2.4c-3.894-.449-7.962-1.996-7.962-8.86A7.084 7.084 0 018.18 12.68a6.6 6.6 0 01.175-4.755s1.466-.471 4.813 1.839a16.105 16.105 0 018.75 0c3.346-2.333 4.812-1.84 4.812-1.84a6.598 6.598 0 01.175 4.756A7.042 7.042 0 0128.7 17.5c0 6.885-4.09 8.41-7.985 8.859.418.434.74.955.944 1.528.204.572.285 1.183.238 1.791 0 2.4-.022 4.329-.022 4.934 0 .471.328 1.032 1.203.853 3.935-1.359 7.271-4.107 9.413-7.754a18.332 18.332 0 002.28-12.158c-.67-4.201-2.776-8.022-5.94-10.78C25.669 2.018 21.653.503 17.5.5z"
                ></path>
            </svg>
        </div>
    );
}

export default GitHubIconWhite;