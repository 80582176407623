import React from 'react';
import styled from 'styled-components';
import RoundSocialMediaButtonWhite from '../../../ui/Buttons/SocialMediaRound.button.component';

const SocialIcons = () => {
    const socialIcons = [
        'linkedin',
        'tg',
        'github'
    ]
    
    return (
        <IconsWrapper>
            {socialIcons.map((type, index) => (
                <RoundSocialMediaButtonWhite key={index} type={type} />
            ))}
        </IconsWrapper>
    );
};

const IconsWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  height: 35px;
  width: 145px;
  max-width: 100%;
  align-items: center;
  gap: 20px;
  justify-content: flex-start;
`;

export default SocialIcons;