import React from "react";

function DownloadIconFooter() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill="url(#pattern0_61_539)"
                fillOpacity="0.42"
                d="M0 0H24V24H0z"
            ></path>
            <defs>
                <pattern
                    id="pattern0_61_539"
                    width="1"
                    height="1"
                    patternContentUnits="objectBoundingBox"
                >
                    <use transform="scale(.01111)" xlinkHref="#image0_61_539"></use>
                </pattern>
                <image
                    id="image0_61_539"
                    width="90"
                    height="90"
                    xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAACc0lEQVR4nO3dTW4TMRyGca8oN6B83Ad1wU0oLBtuUC7Gx4pyCGCNgkSyepDVCWqjmYkT2a8dz/uTIlXtaPL3I3eqROokBDOzfgFPgI/AT+AHcBu/V3uu7nAfdt9t7bm6w/1O3ver9lzdYULtubqDQzt0V/COduiu4B3t0F3BO9qhu4J3tEN3haXuaOAp8B74CvyhsDA9R2lxbV+Ad8CFOvIr4DtCoV7oh+7i2pU7WRq5odC72OV39nC5YMGho2tF6HhNXnroz4rQ6xorC22FXitCVxHOZB6HzsyhRRxaxKFFHFrEoUUcWsShRRxaxKFFHFrEoUUcWsShRRxaxKFFHFrEoUUcWsShRRxaxKFFHFrknEJvgRvgcnjErzfHLmzm/JuR88fnXFzom5FzX03FPnKeeI6rkWNXuYYv0TZlYae4nDj/a+Bv6sJGzht37ZuJY5/lGj5n09SFZQ09FTtMSI08HPsi1/ChtFyDAqsDz/PoMpIwz+jlYu/YD7mGD6XlGpS0MP9jH5jnqHPlEEojr+3cr/rDy8jMz5PPkXPwUBr5bVJ24yk/K7GTd0JplLE9tCtPnDX7Tt7JPevY8JxDbApGjnLNObcAWo9N4chRnprzi6Dl2AgiR3mrji+EVmMjihyVqft4MbQYG2HkqGxl/X9BbVNiqyP3GDrl/Qt55F5DT8auFbnn0Ayv7uL7yc+Hx6rEK75UPYduikOLOLSIQ4s4tIhDizi0iEOLOHRHoavcU6kxv7u9S1hjJHcJi7eOXLq3itAXw90Ml+qb7KOjhnuT3i008ktJ5L2dfR2vV53/gVwDn+Llwh+CZmZmZmZmoUf/AGHOHed19Dl0AAAAAElFTkSuQmCC"
                ></image>
            </defs>
        </svg>
    );
}

export default DownloadIconFooter;