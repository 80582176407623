import React, {forwardRef} from 'react';
import styled from 'styled-components';
import SocialIcons from './components/SocialIcons.component';
import HeroBlock from './components/HeroBlock.component';
import Header from './components/Header.component';
import backgroundImage from '../../assets/StarsBackground.png';

const LandingPage = forwardRef(({scrollOnClick}, ref) => {
    return (
        <Main ref={ref}>
            <BackgroundImage src={backgroundImage} alt="" />
            <ContentWrapper>
                <Header scrollOnClick={scrollOnClick} />
                <HeroBlock scrollOnClick={scrollOnClick} />
                <SocialIcons />
            </ContentWrapper>
        </Main>
    );
});

const BackgroundImage = styled.img`
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;

    @media (max-width: 399px) {
        height: 100%;
    }
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  margin-bottom: -30px;
  width: 1300px;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
    z-index: 2;

    @media (max-width: 1441px) {
        width: 100%;
        max-width: none;
    }
    
`;

const Main = styled.main`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    align-items: center;
    padding: 30px 80px 150px;
    box-sizing: border-box;

    @media (max-width: 1441px) {
        width: 100%;
        padding: 30px 3% 150px;
        max-width: none;
    }

    @media (max-width: 599px) {
        padding: 30px 3% 80px;
    }
`;

export default LandingPage;