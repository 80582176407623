import React from "react";
import styled from "styled-components";

const ProfileImage = ({ src, alt }) => {
    return(
        <CropContainer>
            <StyledImage src={src} alt={alt} loading="lazy" />
        </CropContainer>
    )
};

const CropContainer = styled.div`
    max-width: 640px;
    max-height: 640px;
    overflow: hidden;

    @media (max-width: 866px) {
        max-width: 100%;
        max-height: 400px;
    }
`;

const StyledImage = styled.img`
    object-fit: cover;
    border-radius: 16px;
    width: 640px;
    height: 640px;

    @media (max-width: 1631px) {
        width: 540px;
        height: 640px;
    }
    
    @media (max-width: 1281px) {
        width: 440px;
        height: 640px;
        //margin: ;
    }

    @media (max-width: 1191px) {
        width: 350px;
        height: 640px;
        //margin: ;
    }
    
    

    @media (max-width: 991px) {
        width: 350px;
        height: 640px;
    }

    @media (max-width: 866px) {
        width: 100%;
        height: 400px;
        object-position: center -80px;
    }

    @media (max-width: 559px) {
        width: 100%;
        height: 300px;
        object-position: center -50px;
    }

    @media (max-width: 686px) {
        width: 100%;
        height: 300px;
        object-position: center -50px;
    }

    @media (max-width: 499px) {
        width: 100%;
        height: 200px;
        object-position: center -50px;
    }
`;
export default ProfileImage;