import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import StatisticCard from './components/StatisticsCard.component';
import StatisticsIcon from "../../ui/Icons/Statistics.icon";

const statisticsData = [
    { value: "+24", unit: "K", description: "New users" },
    { value: "+1,5", unit: "M", description: "Successful transactions" },
    { value: "+5000", unit: "%", description: "Business growth" }
];

function StatisticsSection() {
    const sectionRef = useRef(null); // Реф для наблюдения
    const [hasAnimated, setHasAnimated] = useState(false); // Анимировалось ли уже

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setHasAnimated(true);
                    observer.disconnect(); // Отключаем наблюдатель после срабатывания
                }
            });
        }, { threshold: 0.6 }); // Наблюдаем, когда хотя бы 30% секции видимы

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => observer.disconnect();
    }, []);


    return (
        <Section className="statistics-section" ref={sectionRef}>
            <HeaderBadge>
                <StatisticsIcon />
                <BadgeText>Statistics</BadgeText>
            </HeaderBadge>
            <SectionTitleWrapper>
                <SectionTitle>
                    One-year results of my work <br /> in <HighlightText>AirDrop Hunter </HighlightText>team as a developer{" "}
                </SectionTitle>
            </SectionTitleWrapper>
            <StatisticsCardContainer>
                {statisticsData.map((stat, index) => (
                    <StatisticCard
                        key={index}
                        value={stat.value}
                        unit={stat.unit}
                        description={stat.description}
                        triggerAnimation={hasAnimated}
                    />
                ))}
            </StatisticsCardContainer>
        </Section>
    );
}

const Section = styled.section`
    display: flex;
    align-items: center;
    flex-direction: column;
    //gap: 40px;
    color: var(--grey, #848b9a);
    letter-spacing: -0.32px;
    //justify-content: start;
    padding: 30px 10%;
    box-sizing: border-box;
    width: 100%;
    margin-top: 80px;

    @media (max-width: 1441px) {
        padding: 30px 3% 0 3%;
    }

    @media (max-width: 1280px) {
        padding: 20px 8% 0 8%;
    }
    
    @media (max-width: 991px) {
        flex-direction: column;
        padding: 20px;
    }
`;

const SectionTitleWrapper = styled.div`
    display: flex;
    justify-content: start;
    width: 100%;
    margin-top: 10px;
`;


const HeaderBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  align-self: flex-start;
  gap: 10px;
  font-size: 16px;
  color: var(--grey);
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  letter-spacing: -0.32px;
  padding: 11px 30px 11px 11px;
  @media (max-width: 991px) {
    padding-right: 20px;
    white-space: initial;
  }
`;

const BadgeIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 34px;
  align-self: stretch;
  margin: auto 0;
`;

const BadgeText = styled.span`
    align-self: stretch;
    margin: auto 0;
`;

const SectionTitle = styled.h2`
    color: var(--dark-blue);
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: -1.2px;
    text-align: start;
    justify-content: start;
    margin: 0;
    @media (max-width: 991px) {
        max-width: 100%;
    }

    @media (max-width: 699px) {
        font-size: 35px;
        line-height: 35px;
    }

    @media (max-width: 499px) {
        font-size: 25px;
        line-height: 25px;
    }
`;

const HighlightText = styled.span`
    color: rgba(89, 136, 255, 1);
`;

const StatisticsCardContainer = styled.div`
    display: flex;
    margin-top: 35px;
    width: 100%;
    align-items: center;
    gap: 20px;
    font-family: Manrope, sans-serif;
    font-weight: 500;
    line-height: 1;
    justify-content: flex-start;
    flex-wrap: wrap;
    @media (max-width: 991px) {
        height: 100%;
    }

    @media (max-width: 849px) {
        gap: 10px;
    }
`;

export default StatisticsSection;