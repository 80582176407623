import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import HeaderTransparentButton from '../../../ui/Buttons/HeaderHyperlink.button.component';
import MobileMenu from "./Menu.component";

const menuItems = [
    { label: 'About Me', id: 'about' },
    { label: 'Working Experience', id: 'experience' },
    { label: 'Skills', id: 'skills' },
    { label: 'Social Media', id: 'social' }
];

const Header = ({scrollOnClick}) => {
    const [menuOpen, setMenuOpen] = useState(false); // Состояние для управления меню

    const toggleMenu = () => {
        setMenuOpen(!menuOpen); // Переключение состояния меню
    }

    useEffect(() => {
        if (menuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [menuOpen]);

    return (
        <>
            <HeaderWrapper>
                <Logo>Aleksandr Titov</Logo>
                <Nav>
                    {menuItems.map((item) => (
                        <HeaderTransparentButton
                            key={item.id}
                            id={item.id}
                            text={item.label}
                            section={item.id}
                            onClick={scrollOnClick}
                        />
                    ))}
                </Nav>
                <MobileMenuIcon onClick={toggleMenu}>
                    {menuOpen ? <CloseIcon /> : <MenuIcon />}
                </MobileMenuIcon>
            </HeaderWrapper>
            {menuOpen && <MobileMenu isOpen={menuOpen} menuItems={menuItems} scrollOnClick={scrollOnClick} toggleMenu={toggleMenu} />}
        </>
    );
};

const HeaderWrapper = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    padding: 18px 33px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(26.05px);
    border-radius: 30px;
    font: 600 16px/1 Inter, sans-serif;
    letter-spacing: -0.48px;
    z-index: 1000;

    @media (max-width: 1049px) {
        padding: 8px 20px;
    }
`;

const Logo = styled.div`
    color: #fff;
    text-transform: uppercase;
    flex-grow: 0;
    font-weight: 600;
    max-width: 250px;
    line-height: 24px;

    @media (max-width: 1441px) {
        max-width: 300px;
    }
`;

const Nav = styled.nav`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    gap: 10px;
    color: #fff;

    @media (max-width: 1049px) {
        display: none; /* Hide the nav on small screens */
    }
`;

const MobileMenuIcon = styled.div`
    display: none; /* Hide on larger screens */
    @media (max-width: 1049px) {
        display: flex; /* Show on small screens */
        margin-left: auto; /* Push the menu icon to the right */
    }
`;

const MenuIcon = () => (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M28.6367 20.1667H11.0001C9.98756 20.1667 9.16675 19.3459 9.16675 18.3333C9.16675 17.3208 9.98756 16.5 11.0001 16.5H28.6367C29.6493 16.5 30.4701 17.3208 30.4701 18.3333C30.4701 19.3459 29.6493 20.1667 28.6367 20.1667Z"
            fill="white"/>
        <path
            d="M11.0001 25.6667H34.5034C35.5159 25.6667 36.3367 26.4875 36.3367 27.5C36.3367 28.5125 35.5159 29.3333 34.5034 29.3333H11.0001C9.98756 29.3333 9.16675 28.5125 9.16675 27.5C9.16675 26.4875 9.98756 25.6667 11.0001 25.6667Z"
            fill="white"/>
    </svg>
);

const CloseIcon = () => (
    <div style={{width: "44px", height: "44px", justifyContent: "center", alignItems: "center", display: "flex"}}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                fill="#fff"
                d="M15.719 14.39L9.325 8l6.394-6.343a.946.946 0 00-.04-1.278A.93.93 0 0014.41.32L7.998 6.617 1.698.274a.93.93 0 00-1.319 0 .944.944 0 000 1.336L6.67 7.944.277 14.278a.944.944 0 000 1.336.931.931 0 00.663.273.931.931 0 00.655-.264l6.403-6.352 6.412 6.456a.932.932 0 001.59-.67.945.945 0 00-.281-.666z"
            ></path>
        </svg>
    </div>

);

export default Header;
