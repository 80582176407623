import {ContactMeButtonStyled} from "./Buttons.styles";

const ContactMeButton = ({scrollOnClick}) => {
    return(
        <ContactMeButtonStyled onClick={() => scrollOnClick('social')}>
            Contact Me
        </ContactMeButtonStyled>
    )
};


export default ContactMeButton;
