import React from "react";
import { config } from '../../consts';
import ReactGA from "react-ga4";



function TelegramIconGray() {
    const handleClick = () => {
        ReactGA.event({
            category: "button",
            action: "Social media click",
            label: `Telegram White`,
        });

        window.open(config.urls.telegram, "_blank", "noopener,noreferrer");
    };
    
    return (
        <div onClick={handleClick} style={{cursor: 'pointer'}}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                fill="none"
                viewBox="0 0 35 35"
            >
                <path
                    fill="#848B9A"
                    d="M17.5 0a17.5 17.5 0 100 35 17.5 17.5 0 000-35zm5.564 26.517a1.233 1.233 0 01-1.753.615L16.56 23.44l-3.05 2.813a.524.524 0 01-.498.069l.585-5.23.018.015.012-.104s8.55-7.783 8.897-8.114c.353-.331.237-.403.237-.403.02-.403-.633 0-.633 0L10.8 19.773l-4.717-1.606s-.725-.26-.793-.832c-.072-.567.817-.875.817-.875l18.754-7.45s1.542-.687 1.542.45l-3.34 17.057z"
                ></path>
            </svg>
        </div>
    );
}

export default TelegramIconGray;