import React from "react";
import styled from "styled-components";
import ReactGA from "react-ga4";

function SocialMediaButton({ Icon, name, url }) {
    const handleClick = () => {
        ReactGA.event({
            category: "button",
            action: "Social media click (still have questions)",
            label: `${name}`,
        });

        window.open(url, "_blank", "noopener,noreferrer");
    };

    return (
        <StyledDiv>
            <Button onClick={handleClick}>
                {Icon}
                <Name>{name}</Name>
            </Button>
        </StyledDiv>
    );
}

const StyledDiv = styled.div`
    display: flex;
    justify-content: center;
`;

const Button = styled.button`
    display: flex;
    align-items: center;
    border-radius: 50px;
    text-decoration: none;
    background: var(--white, #fff);
    box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.05);
    width: 100%;
    padding: 8px 20px;
    gap: 10px;
    margin-top: 10px;
    border: none;
    cursor: pointer;
    justify-content: flex-start;

    @media (max-width: 991px) {
        padding-right: 20px;
        white-space: initial;
    }

    @media (max-width: 399px) {
        width: 100%;
    }
`;

// const Icon = styled.img`
//     aspect-ratio: 1;
//     object-fit: contain;
//     width: 40px;
//     margin-right: 0px;
// `;

const Name = styled.span`
    font-size: 20px;
    font-weight: bold;
    flex-grow: 1;
    text-align: center;
    text-decoration: none;
    color: var(--dark-blue);;
`;

export default SocialMediaButton;