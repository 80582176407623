import React from "react";
import styled from "styled-components";
import LinkedinIconGray from "../../../ui/Icons/LinkedinGray.icon";
import TelegramGrayIcon from "../../../ui/Icons/TelegramGray.icon";
import GitHubIconGray from "../../../ui/Icons/GitHubGray.icon";
import {config} from "../../../consts";
import ReactGA from "react-ga4";

function ContactsSection() {
    const socialIcons = [
        { src: LinkedinIconGray, alt: "Linkedin: Aleksandr Titov" },
        { src: TelegramGrayIcon, alt: "Telegram: @ZonaV_Official", },
        { src: GitHubIconGray, alt: "GitHub: @0xZonaV" },
    ];

    const handleMailtoClick = () => {
        ReactGA.event({
            category: "email",
            action: "email click",
            label: "email sending",
        });

        window.location.href = `mailto:${config.email}`; // Открытие почтового клиента
    };
    
    return (
        <ContactsWrapper>
            <SectionTitle>Contacts</SectionTitle>
            <ContactsDiv>
                <EmailAddress onClick={handleMailtoClick}>{config.email}</EmailAddress>
                <SocialMediaIcons>
                    {socialIcons.map((icon, index) => {
                        const Icon = icon.src;
                        return(
                            <Icon key={index} alt={icon.alt} />
                        );
                    })}
                </SocialMediaIcons>
            </ContactsDiv>
        </ContactsWrapper>
    );
}

const ContactsWrapper = styled.section`
  border-radius: 10px;
  background-color: rgba(1, 20, 58, 1);
  align-self: stretch;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: flex-start;
  flex: 1;
  flex-basis: 0;
  padding: 31px 80px 31px 35px;

    min-height: 250px;

    @media (max-width: 991px) {
        padding: 31px 20px;
    }
    
`;

const SectionTitle = styled.h3`
  color: #fff;
  letter-spacing: -1px;
  font: 600 20px/1 Manrope, sans-serif;
  margin: 0 0 78px;

  @media (max-width: 991px) {
    margin-bottom: 40px;
  }
`;

const ContactsDiv = styled.div`
    margin-top: auto;
`

const EmailAddress = styled.p`
    color: #848b9a;
    letter-spacing: -1.08px;
    font: 600 18px/1 Manrope, sans-serif;
    margin: 0 0 27px;
    cursor: pointer;
`;

const SocialMediaIcons = styled.div`
  display: flex;
  height: 35px;
  align-items: center;
  gap: 20px;
  justify-content: flex-start;
`;

// const SocialIcon = styled.img`
//   aspect-ratio: 1;
//   object-fit: contain;
//   object-position: center;
//   width: 35px;
//   align-self: stretch;
//   margin: auto 0;
// `;

export default ContactsSection;