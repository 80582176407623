import React from "react";
import styled from "styled-components";

function NavigationSection({scrollToSection}) {
    const navItems = [
        {
            name: "Main",
            id: "main",
        },
        {
            name: "About me",
            id: "about",
        },
        {
            name: "Working experience",
            id: "experience",
        },
        {
            name: "My Skills",
            id: "social",
        },
    ];

    return (
        <NavWrapper>
            <SectionTitle>Navigation</SectionTitle>
            <NavList>
                {navItems.map((item, index) => (
                    <NavItem key={index} onClick={() => scrollToSection(item.id)} >{item.name}</NavItem>
                ))}
            </NavList>
        </NavWrapper>
    );
}

const NavWrapper = styled.nav`
  border-radius: 10px;
  background-color: rgba(1, 20, 58, 1);
  align-self: stretch;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: flex-start;
  font-family: Manrope, sans-serif;
  font-weight: 600;
  flex: 1;
  padding: 33px 80px 33px 35px;

    min-height: 150px;

    @media (max-width: 991px) {
        padding: 31px 20px;
        flex-grow: 1;
    }
`;

const SectionTitle = styled.h3`
  color: #fff;
  font-size: 20px;
  line-height: 1;
  letter-spacing: -1px;
  margin: 0 0 37px;
`;

const NavList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const NavItem = styled.li`
  color: #848b9a;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.9px;
`;

export default NavigationSection;