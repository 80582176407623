import React, {useEffect, useRef} from 'react';
import { GlobalStyles } from '@mui/material';
import LandingPage from './blocks/HeaderBlock';
import AboutMeSection from './blocks/AboutMeSection';
import StatisticsSection from './blocks/StatisticsBox';
import SkillsSection from './blocks/SkillsBox';
import Footer from './blocks/Footer';
import SocialMediaContact from './blocks/SocialMedaiSection';
import ProjectCardWrapper from './blocks/ProjectBox';
import ReactGA from 'react-ga4';
import ScrollTracker from "./ScrollTracker";

function App() {
    const mainSectionRef = useRef(null);
    const aboutMeSectionRef = useRef(null);
    const projectsSectionRef = useRef(null);
    const skillsSectionRef = useRef(null);
    const socialMediaSectionRef = useRef(null);

    useEffect(() => {
        ReactGA.initialize('G-M36NKDN60K');

        ReactGA.send('pageview');
    }, []);


    // Функция для скролла к разделу
    const scrollToSection = (sectionName) => {
        ReactGA.event({
            category: "scroll",
            action: "Scroll button click",
            label: `Section: ${sectionName}`,
        });

        // eslint-disable-next-line default-case
        switch (sectionName) {
            case 'main': {
                return mainSectionRef.current.scrollIntoView({ behavior: "smooth" });
            }

            case 'about': {
                return aboutMeSectionRef.current.scrollIntoView({ behavior: "smooth" });
            }

            case 'experience': {
                return projectsSectionRef.current.scrollIntoView({ behavior: "smooth" });
            }

            case 'skills': {
                return skillsSectionRef.current.scrollIntoView({ behavior: "smooth" });
            }

            case 'social': {
                return socialMediaSectionRef.current.scrollIntoView({ behavior: "smooth" });
            }
        }
    };

    return (
        <>
            <GlobalStyles
                styles={{
                    ':root': {
                        '--main-blue': '#3375F6',
                        '--white': '#fff',
                        '--soft-blue': '#5988FF',
                        '--background-grey': '#F5F5F9',
                        '--dark-blue': '#000E2B',
                        '--accent-red': '#F75B38',
                        '--grey': '#848B9A',
                        '--accent-green': '#5CD7D0',
                        '--transparent-white': 'rgba(255,255,255,0.1)',
                    },
                    'html, body': {
                        margin: 0,
                        padding: 0,
                        width: '100%',
                        height: '100%',
                        "overflow-x": "hidden",
                        backgroundColor: '#F5F5F9',
                        boxSizing: 'border-box',
                    },
                    '*': {
                        boxSizing: 'border-box', // Для всех элементов
                    },
                    '#root': {
                        width: '100%',
                        height: '100%',
                    },
                }}
            />
            <ScrollTracker />
            <LandingPage ref={mainSectionRef} scrollOnClick={scrollToSection} />
            <AboutMeSection ref={aboutMeSectionRef} scrollOnClick={scrollToSection} />
            <ProjectCardWrapper ref={projectsSectionRef} />
            <StatisticsSection />
            <SkillsSection ref={skillsSectionRef} />
            {/*<FAQSection />*/}
            <SocialMediaContact ref={socialMediaSectionRef} />
            <Footer scrollOnClick={scrollToSection} />
        </>
    );
}

export default App;