import React from "react";
import styled from "styled-components";

function SkillCard({ name, image }) {
    return (
        <CardWrapper>
            <SkillImage src={image} alt={`${name} icon`} loading="lazy" />
            <SkillName>{name}</SkillName>
        </CardWrapper>
    );
}

const CardWrapper = styled.div`
  align-items: center;
  border-radius: 25px;
  background-color: var(--white);
  box-shadow: 1px 4px 11px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  min-height: 255px;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  width: 160px;
  padding: 19px 20px;

    @media (max-width: 1280px) {
        padding: 10px 10px;
        min-height: 250px;
        width: 150px;
    }

    @media (max-width: 1100px) {
        padding: 10px 10px;
        min-height: 200px;
        width: 100px;
    }

    @media (max-width: 991px) {
        padding: 10px 10px;
        min-height: 250px;
        width: 150px;
    }
    
    @media (max-width: 949px) {
        padding: 10px 10px;
        min-height: 200px;
        width: 100px;
    }

    @media (max-width: 799px) {
        width: 200px;
        min-height: 300px;
        padding: 10px 10px;
    }

    @media (max-width: 660px) {
        width: 150px;
        min-height: 200px;
        padding: 10px 10px;
    }

    @media (max-width: 510px) {
        width: 120px;
        min-height: 250px;
        padding: 10px 10px;
    }

    @media (max-width: 489px) {
        width: 120px;
        min-height: 210px;
        padding: 10px 10px;
    }

    @media (max-width: 429px) {
        width: 100px;
        min-height: 200px;
        padding: 10px 10px;
    }

    @media (max-width: 419px) {
        width: 120px;
        min-height: 190px;
        padding: 10px 10px;
    }
`;

const SkillImage = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 160px;
  border-radius: 15px;
  box-shadow: 1px 4px 25px rgba(0, 0, 0, 0.05);
  max-width: 100%;

    @media (max-width: 799px) {
        width: 200px;
    }

    @media (max-width: 660px) {
        width: 150px;
    }


    @media (max-width: 429px) {
        width: 120px;
    }
`;

const SkillName = styled.span`
  margin-top: 20px;

    @media (max-width: 991px) {
        margin-top: 30px;
    }
`;

export default SkillCard;