import React from "react";

function StatisticsIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            fill="none"
            viewBox="0 0 34 34"
        >
            <g clipPath="url(#clip0_44_183)">
                <path
                    fill="#F5F5F9"
                    d="M17.486 34C26.606 34 34 26.606 34 17.486S26.606.97 17.486.97.97 8.365.97 17.486C.971 26.606 8.365 34 17.486 34z"
                ></path>
                <path
                    fill="#5CD7D0"
                    d="M12.684 11.375a7.772 7.772 0 104.824-1.66l-.022 7.77-4.802-6.11z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_44_183">
                    <path fill="#fff" d="M0 0H34V34H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default StatisticsIcon;