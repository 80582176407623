import React, {useEffect, useState} from "react";
import styled from "styled-components";

function StatisticCard({ value, unit, description, triggerAnimation }) {
    const [displayValue, setDisplayValue] = useState(0); // Отображаемое значение

    useEffect(() => {
        if (triggerAnimation) {
            const parsedValue = parseFloat(value.replace(/[^\d.,]/g, "").replace(",", ".")); // Парсим число (учитывая запятые)
            const isFloat = !Number.isInteger(parsedValue); // Проверка, является ли число дробным
            const endValue = parsedValue;
            const duration = 800; // Длительность анимации в миллисекундах
            let startTime = null;

            const animateValue = (timestamp) => {
                if (!startTime) startTime = timestamp;
                const progress = timestamp - startTime;

                // Вычисляем текущее значение
                let currentValue;
                if (isFloat) {
                    currentValue = Math.min((progress / duration) * endValue, endValue).toFixed(1); // Шаг 0.1 для дробных
                } else {
                    currentValue = Math.min(Math.floor((progress / duration) * endValue), endValue); // Шаг 1 для целых
                }

                setDisplayValue(currentValue);

                if (progress < duration) {
                    requestAnimationFrame(animateValue);
                }
            };

            requestAnimationFrame(animateValue);
        }
    }, [triggerAnimation, value]);

    return (
        <CardWrapper>
            <StatValue>
                +{String(displayValue).replace(".", ",")}
                <HighlightUnit>{unit}</HighlightUnit>
            </StatValue>
            <StatDescription>{description}</StatDescription>
        </CardWrapper>
    );
}

const CardWrapper = styled.div`
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: var(--white);
  box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  min-width: 240px;
    max-height: 295px;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  flex-basis: 0;
  margin: auto 0;
  padding: 97px 62px;
    height: 100%;

    @media (max-width: 866px) {
        padding: 50px 30px;
    }

    @media (max-width: 849px) {
        padding: 50px 15px;
        //margin-left: 10px;
    }
  
  //@media (max-width: 991px) {
  //  padding: 0 20px;
  //}
`;

const StatValue = styled.div`
  color: var(--dark-blue);
  font-size: 64px;
  letter-spacing: -1.92px;
    font-weight: 500;
  @media (max-width: 849px) {
    font-size: 40px;
  }
`;

const HighlightUnit = styled.span`
  color: rgba(89, 136, 255, 1);
`;

const StatDescription = styled.div`
  color: var(--grey);
  text-align: center;
  font-size: 20px;
  letter-spacing: -0.6px;
  margin-top: 17px;
`;

export default StatisticCard;