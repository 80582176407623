import React from "react";
import { HeaderStyledButton } from './Buttons.styles';

const HeaderTransparentButton = ({text, section,onClick}) => {
    
    return (
        <HeaderStyledButton onClick={() => onClick(section)}>
            {text}
        </HeaderStyledButton>
    );
};


export default HeaderTransparentButton;