import React from "react";

function TelegramIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <path
                fill="#3375F6"
                d="M20 0a20 20 0 100 40 20 20 0 000-40zm6.359 30.305a1.41 1.41 0 01-2.004.703l-5.43-4.219-3.484 3.215a.6.6 0 01-.57.078l.668-5.977.021.017.014-.118s9.77-8.895 10.168-9.274c.403-.378.27-.46.27-.46.023-.461-.723 0-.723 0l-12.945 8.328-5.391-1.836s-.828-.297-.906-.95c-.082-.648.933-1 .933-1l21.434-8.515s1.762-.785 1.762.515l-3.817 19.493z"
            ></path>
        </svg>
    );
}

export default TelegramIcon;