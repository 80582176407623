import React from "react";
import DownloadIcon from '../Icons/Download.icon';
import {DownloadButtonText, DownloadButtonWrapper} from './Buttons.styles';
import {config} from "../../consts";
import ReactGA from "react-ga4";

const DownloadResumeButton = () => {
    const handleClick = () => {
        ReactGA.event({
            category: "button",
            action: "Resume click",
            label: "Resume",
        });

        window.open(config.urls.resume, "_blank", "noopener,noreferrer");
    };

    return (
        <DownloadButtonWrapper onClick={() => handleClick()}>
            <DownloadIcon />
            <DownloadButtonText>Download Resume</DownloadButtonText>
        </DownloadButtonWrapper>
    );
};
export default DownloadResumeButton;