import React from "react";
import styled from "styled-components";
import DownloadIconFooter from "../../../ui/Icons/DownloadFooter.icon";
import {config} from "../../../consts";
import ReactGA from "react-ga4";

function ResumeSection() {
    const handleClick = () => {
        ReactGA.event({
            category: "button",
            action: "Resume click",
            label: "Resume",
        });

        window.open(config.urls.resume, "_blank", "noopener,noreferrer");
    };

    return (
        <ResumeWrapper>
            <SectionTitle>Resume</SectionTitle>
            <DownloadContainer onClick={handleClick}>
                <DownloadText>Download resume</DownloadText>
                <DownloadIconFooter alt="Download resume icon" />
            </DownloadContainer>
        </ResumeWrapper>
    );
}

const ResumeWrapper = styled.section`
  border-radius: 10px;
  background-color: rgba(1, 20, 58, 1);
  align-self: stretch;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: flex-start;
  font-family: Manrope, sans-serif;
  font-weight: 600;
  flex: 1;
  flex-basis: 0;
  padding: 35px 80px 35px 35px;

    min-height: 250px; 

    @media (max-width: 1196px) {
        padding: 35px 20px 35px 35px;
    }

    @media (max-width: 991px) {
        padding: 31px 20px;

        flex-grow: 1;
    }
    
    
`;

const SectionTitle = styled.h3`
  color: #fff;
  font-size: 20px;
  line-height: 1;
  letter-spacing: -1px;
  margin: 0 0 122px;

  @media (max-width: 991px) {
    margin-bottom: 40px;
  }
`;

const DownloadContainer = styled.div`
  display: flex;
  gap: 16px;
  font-size: 18px;
  color: #848b9a;
  letter-spacing: -0.9px;
  line-height: 1.8;
  align-items: center;
    margin-top: auto;
  cursor: pointer;
`;

const DownloadText = styled.span`
  flex-grow: 1;
  cursor: pointer;
`;

// const DownloadIcon = styled.img`
//   aspect-ratio: 1;
//   object-fit: contain;
//   object-position: center;
//   width: 24px;
// `;

export default ResumeSection;