import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

function ScrollTracker() {
    const [scrollDepth, setScrollDepth] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY; // Прокрученная часть страницы
            const windowHeight = window.innerHeight; // Видимая часть страницы
            const fullHeight = document.documentElement.scrollHeight; // Общая высота страницы

            // Рассчитываем процент прокрутки
            const scrolled = (scrollTop + windowHeight) / fullHeight * 100;

            // Проверяем глубину скроллинга
            if (scrolled > 20 && scrollDepth < 20) {
                setScrollDepth(20);
                ReactGA.event({
                    category: 'Scroll Depth',
                    action: '20%',
                    label: 'User reached 20% scroll depth',
                });
            } else if (scrolled > 40 && scrollDepth < 40) {
                setScrollDepth(40);
                ReactGA.event({
                    category: 'Scroll Depth',
                    action: '40',
                    label: 'User reached 40 scroll depth',
                });
            } else if (scrolled > 60 && scrollDepth < 60) {
                setScrollDepth(60);
                ReactGA.event({
                    category: 'Scroll Depth',
                    action: '60%',
                    label: 'User reached 60% scroll depth',
                });
            } else if (scrolled > 80 && scrollDepth < 80) {
                setScrollDepth(80);
                ReactGA.event({
                    category: 'Scroll Depth',
                    action: '80%',
                    label: 'User reached 80% scroll depth',
                });
            } else if (scrolled > 100 && scrollDepth < 100) {
                setScrollDepth(100);
                ReactGA.event({
                    category: 'Scroll Depth',
                    action: '100%',
                    label: 'User reached 100% scroll depth',
                });
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrollDepth]);

    return null;
}

export default ScrollTracker;
