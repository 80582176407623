import React, {forwardRef} from "react";
import styled from "styled-components";
import SocialMediaButton from '../../ui/Buttons/SocialMedia.button.component';
import GitHubIcon from "../../ui/Icons/GitHub.icon";
import LinkedinIcon from "../../ui/Icons/Linkedin.icon";
import TelegramIcon from "../../ui/Icons/Telegram.icon";
import {config} from "../../consts";

const socialMediaData = [
    {
        icon: <LinkedinIcon />,
        name: "LinkedIn",
        url: config.urls.linkedin,
    },
    {
        icon: <TelegramIcon />,
        name: "Telegram",
        url: config.urls.telegram,
    },
    {
        icon: <GitHubIcon />,
        name: "GitHub",
        url: config.urls.github,
    }
];

const SocialMediaContact = forwardRef((props, ref) => {
    return (
        <ContactSection ref={ref}>
            <Heading>Still have questions?</Heading>
            <Subheading>Contact me via</Subheading>
            <ButtonContainer>
                {socialMediaData.map((item, index) => (
                    <SocialMediaButton key={index} Icon={item.icon} name={item.name} url={item.url} />
                ))}
            </ButtonContainer>
        </ContactSection>
    );
});

const ContactSection = styled.section`
    margin-top: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
    //gap: 40px;
    color: var(--grey);
    letter-spacing: -0.32px;
    //justify-content: start;
    padding: 30px 10%;
    box-sizing: border-box;
    width: 100%;

    @media (max-width: 991px) {
        padding: 31px 20px;
    }
    
  align-self: center;
  max-width: 100%;
  justify-content: flex-start;
  font: 600 16px Inter, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Heading = styled.h2`
  color: var(--dark-blue);
  font-size: 64px;
  line-height: 1.2;
  letter-spacing: -3.2px;
    margin: 0;
    @media (max-width: 991px) {
        max-width: 100%;
        font-size: 40px;
    }

    @media (max-width: 399px) {
        max-width: 100%;
        font-size: 30px;
        letter-spacing: -1.2px;
    }
`;

const Subheading = styled.p`
  color: var(--grey);
  text-align: center;
  font-weight: 400;
  letter-spacing: -0.32px;
  margin-top: 30px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ButtonContainer = styled.div`
  align-self: center;
  display: flex;
  margin-top: 0px;
  width: 272px;
  max-width: 100%;
  flex-direction: column;
  color: var(--black);
  white-space: nowrap;
  text-align: center;
    justify-content: center;
  letter-spacing: -0.48px;
  line-height: 1;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

export default SocialMediaContact;