import React from "react";
import LinkedinIconWhite from '../Icons/LinkedinWhite.icon';
import GitHubIconWhite from '../Icons/GitHubWhite.icon';
import TelegramIconWhite from '../Icons/TelegramWhite.icon';

const RoundSocialMediaButtonWhite = ({ type }) => {

    if (type === 'linkedin') {
        return <LinkedinIconWhite />
    }

    if (type === 'github') {
        return <GitHubIconWhite />
    }

    if (type === 'tg') {
        return<TelegramIconWhite />
    }
};

export default RoundSocialMediaButtonWhite;