import React from "react";
import { styled } from '@mui/material';

const NonClickableComponent = ({text}) => {
    return (
        <StyledBlockchainDeveloper>
            {text}
        </StyledBlockchainDeveloper>
    );
};

const StyledBlockchainDeveloper = styled('section')`
    align-self: stretch;
    border-radius: 500px;
    box-shadow: 0 4px 11px rgba(0, 0, 0, 0.05);
    background-color: #fff;
    padding: 16px 30px;
    color: var(--grey);
    font-family: Inter, sans-serif;
  
    @media (max-width: 991px) {
        padding: 16px 20px;
    }


`;

export default NonClickableComponent;