import React from "react";
import styled from "styled-components";
import ReactGA from "react-ga4";

const ProjectActionButton = ({ text,url }) => {
    const handleClick = () => {
        ReactGA.event({
            category: "button",
            action: "Project website click",
            label: `Url: ${url}`,
        });

        window.open(url, "_blank", "noopener,noreferrer");
    };

    return(
        <Section>
            <StyledButton onClick={handleClick}>{text}</StyledButton>
        </Section>
    )
};

const Section = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    height: 100%;
    margin: auto 0;

    @media (max-width: 1280px) {
        width: 100%;
    }

    @media (max-width: 649px) {
        width: 100%;
        justify-content: start;
    }
`

const StyledButton = styled.button`
  border-radius: 50px;
    text-decoration: none;
  background: var(--main-blue, #3375f6);
  color: var(--white, #fff);
    max-height: 60px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.48px;
  line-height: 1;
  padding: 20px 50px;
  border: none;
  cursor: pointer;
    margin: auto 0;

    @media (max-width: 1280px) {
        width: 100%;
    }

    @media (max-width: 1210px) {
        width: 60%;
    }
    
  @media (max-width: 991px) {
    padding: 20px;
  }

    @media (max-width: 649px) {
        width: 40%;
    }

`;

export default ProjectActionButton;