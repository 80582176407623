import {ContactMeButtonHeaderStyled} from "./Buttons.styles";

const ContactMeButtonHeader = ({scrollOnClick}) => {
    return(
        <ContactMeButtonHeaderStyled onClick={() => scrollOnClick('social')}>
            Contact Me
        </ContactMeButtonHeaderStyled>
    )
};


export default ContactMeButtonHeader;
