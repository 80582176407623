import React from "react";
import styled from "styled-components";

const ProjectDateComponent = ({ date }) => {
    return <DateText>{date}</DateText>;
};

const DateText = styled.time`
  color: var(--grey, #848b9a);
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.4px;
  margin: auto 0;
`;

export default ProjectDateComponent;