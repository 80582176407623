import React from "react";
import styled from "styled-components";
import JobIcon from '../Icons/Job.icon';

const JobBadge = ({ jobType }) => {
    return (
        <BadgeWrapper>
            <JobIcon />
            <BadgeText>{jobType}</BadgeText>
        </BadgeWrapper>
    );
};

const BadgeWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 500px;
    box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    align-self: flex-start;
    gap: 10px;
    font-size: 16px;
    color: var(--grey);
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    letter-spacing: -0.32px;
    padding: 11px 30px 11px 11px;
    @media (max-width: 991px) {
        padding-right: 20px;
        white-space: initial;
    }
`;

const BadgeText = styled.span`
    align-self: stretch;
    margin: auto 0;
`;

export default JobBadge;