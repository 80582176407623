import {Button, styled} from "@mui/material";

export const ContactMeButtonStyled = styled(Button)`
    align-self: stretch;
    border-radius: 50px;
    background: var(--main-blue, #3375f6);
    color: var(--white, #fff);
    letter-spacing: -0.48px;
    padding: 20px 50px;
    font: 600 16px/1 Inter, sans-serif;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-transform: none;

    &:hover {
        background-color: #2860d1;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgba(51, 117, 246, 0.5);
    }

    @media (max-width: 1631px) {
        max-width: 270px;
    }
    
    @media (max-width: 1281px) {
        max-width: 270px;
        
    }
    
    @media (max-width: 991px) {  
        padding: 20px;
    }

    @media (max-width: 866px) {
        max-width: 270px;
        width: 270px;
    }

    @media (max-width: 617px) {
        max-width: 100%;
        width: 100%;
    }
`;

export const ContactMeButtonHeaderStyled = styled(Button)`
    align-self: stretch;
    border-radius: 50px;
    background: var(--main-blue, #3375f6);
    color: var(--white, #fff);
    letter-spacing: -0.48px;
    padding: 20px 50px;
    font: 600 16px/1 Inter, sans-serif;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-transform: none;

    &:hover {
        background-color: #2860d1;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgba(51, 117, 246, 0.5);
    }
`;


export const DownloadButtonWrapper = styled('button')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 16px 50px;
  min-width: 240px;
  min-height: 56px;
  background-color: var(--dark-blue, #000e2b);
  border-radius: 50px;
  border: none;
  cursor: pointer;

  @media (max-width: 991px) {
      min-width: 270px;
  }

    @media (max-width: 617px) {
        max-width: 100%;
        width: 100%;
        justify-content: center;
    }
`;


export const DownloadButtonText = styled('span')`
  color: white;
  font-size: 16px;
  cursor: pointer;  
`;

export const HeaderStyledButton = styled('button')`
    align-self: stretch;
    //min-width: 113px;
    border-radius: 30px;
    background: var(--transparent-white);
    max-height: 40px;
    padding: 12px 20px;
    color: white;
    border: none;
    font-family: Inter, sans-serif;
    cursor: pointer;
    outline: inherit;
`;
