import React from "react";
import styled from "styled-components";
import NavigationSection from './components/Navigation.component';
import ResumeSection from './components/ResumeSection.component';
import ContactsSection from './components/ContactSections.component';

function Footer({scrollOnClick}) {
    return (
        <FooterWrapper>
            <FooterContent>
                <DeveloperInfo>
                    <DeveloperName>Aleksandr Titov</DeveloperName>
                    <DeveloperRole>Middle Blockchain Developer</DeveloperRole>
                </DeveloperInfo>
                <SectionsContainer>
                    <NavigationSection scrollToSection={scrollOnClick} />
                    <ResumeSection />
                    <ContactsSection />
                </SectionsContainer>
            </FooterContent>
        </FooterWrapper>
    );
}

const DeveloperInfo = styled.div`
  margin-bottom: 25px;
`;

const DeveloperName = styled.h2`
  color: #fff;
  letter-spacing: -1px;
  font: 600 20px/1 Manrope, sans-serif;
  margin: 0 0 19px;
`;

const DeveloperRole = styled.p`
  color: #848b9a;
  letter-spacing: -0.8px;
  font: 600 16px/1 Manrope, sans-serif;
  margin: 0;
`;

const FooterWrapper = styled.footer`
    border-radius: 40px 40px 0 0;
    background-color: var(--dark-blue);
    display: flex;
    margin-top: 100px;
    width: 100%;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    padding: 57px 80px 27px;

    @media (max-width: 1129px) {
        max-width: 100%;
        width: 100%;
        margin-top: 40px;
        padding: 57px 40px 27px;
    }

    @media (max-width: 991px) {
        max-width: 100%;
        margin-top: 40px;
        padding: 57px 20px;
    }

    @media (max-width: 725px) {
        min-height: 100%;
        padding: 40px 20px 10px 20px;
    }
`;

const FooterContent = styled.div`
  display: flex;
  width: 90%;
  max-width: 100%;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1; /* Растягиваем контент */
  
  @media (max-width: 1129px) {
    width: 100%;
  }

  @media (max-width: 725px) {
    flex-grow: 1;
  }
`;

const SectionsContainer = styled.div`
  align-self: stretch;
  display: flex;
  align-items: stretch;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  flex-grow: 1;
  @media (max-width: 991px) {
    max-width: 100%;
    gap: 10px;
  }

  @media (max-width: 725px) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;
  }
`;


export default Footer;