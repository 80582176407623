import React from "react";

function JobIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            fill="none"
            viewBox="0 0 34 34"
        >
            <g clipPath="url(#clip0_44_184)">
                <path
                    fill="#F5F5F9"
                    d="M17.348 34c9.128 0 16.527-7.611 16.527-17s-7.4-17-16.527-17C8.22 0 .82 7.611.82 17s7.4 17 16.528 17z"
                ></path>
                <path
                    fill="#F75B38"
                    d="M20.724 19.752v.972c0 .388-.26.647-.648.647s-.647-.259-.647-.647v-.972h-3.886v.972c0 .388-.26.647-.648.647s-.647-.259-.647-.647v-.972h-1.296c-.453 0-.906-.13-1.295-.323v2.59c0 1.101.842 1.943 1.943 1.943h7.771a1.908 1.908 0 001.943-1.943v-2.59a2.948 2.948 0 01-1.295.323h-1.295zm2.59-6.152h-2.59v-.648a1.908 1.908 0 00-1.943-1.943h-2.59a1.908 1.908 0 00-1.943 1.943v.648h-2.59c-.39 0-.649.259-.649.648v2.59c0 1.101.842 1.943 1.943 1.943h9.067a1.908 1.908 0 001.943-1.943v-2.59c0-.389-.26-.648-.648-.648zm-3.885 0h-3.886v-.648c0-.388.259-.647.648-.647h2.59c.389 0 .648.259.648.647v.648z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_44_184">
                    <path fill="#fff" d="M0 0H34V34H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default JobIcon;