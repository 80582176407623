import React, {forwardRef} from "react";
import styled from "styled-components";
import DownloadResumeButton from '../../ui/Buttons/DownloadResume.button.component';
import ContactMeButton from '../../ui/Buttons/ContactMe.button.component';
import NonClickableComponent from '../../ui/Objects/NonClickable.object.component';
import ProfileImage from './components/ProfileImage.component';
import profilePng from '../../assets/Profile picture.png';

const AboutMeSection = forwardRef((props, ref) => {
    const birthYear = 2003;
    const birthMonth = 7;
    
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    
    let age = currentYear - birthYear;
    
    if (currentMonth < birthMonth) {
        age--;
    }
    
    const infoTags = [
        { text: "Blockchain Developer" },
        { text: `${age} years old` }
    ];
    
    
    return (
        <Section ref={ref}>
            <ProfileImage src={profilePng} alt="Aleksandr Titov" />
            <Card>
                <TagContainer>
                    {infoTags.map((tag, index) => (
                        <NonClickableComponent key={index} text={tag.text} />
                    ))}
                </TagContainer>
                <Heading>About me</Heading>
                <Description>
                    My mission is to bring cutting-edge technology to business processes and create secure and efficient blockchain-based solutions. If you are looking for an expert to help you implement blockchain technology and reach new heights, I am ready to collaborate and open to discuss any projects. Contact me to learn more about how we can build a future on blockchain together.
                </Description>
                <ButtonContainer>
                    <ContactMeButton scrollOnClick={props.scrollOnClick} />
                    <DownloadResumeButton />
                </ButtonContainer>
            </Card>
        </Section>
    );
});

const Section = styled.section`
    display: flex;
    gap: 40px;
    color: var(--grey, #848b9a);
    letter-spacing: -0.32px;
    padding: 30px 10%;
    box-sizing: border-box;
    width: 100%;

    @media (max-width: 1440px) {
        padding: 20px 3% 0 3%;
    }

    @media (max-width: 1280px) {
        margin-top: 30px;
        padding: 20px 8% 0 8%;
        gap: 10px;
    }

    @media (max-width: 991px) {
        margin-top: 30px;
        padding: 20px 3% 0 3%;
        gap: 10px;
    }

    @media (max-width: 866px) {
        flex-direction: column;
    }

    @media (max-width: 617px) {
        margin-top: 10px;
        padding: 0;
    }
`;

const Card = styled.article`
    background-color: #fff;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 100px 100px 100px 50px;
    //max-width: 640px;
    max-height: 640px;
    width: 100%;
    margin-left: auto;

    @media (max-width: 1631px) {
        margin-left: 10px;
        max-width: 840px;
        max-height: 640px;
        padding: 80px 100px 100px 50px;
    }

    @media (max-width: 1281px) {
        margin-left: 10px;
        max-width: 840px;
        max-height: 640px;
        padding: 80px 100px 100px 50px;
    }

    @media (max-width: 1076px) {
        margin-left: 10px;
        max-width: 840px;
        max-height: 640px;
        padding: 80px 50px 100px 50px;
    }

    @media (max-width: 991px) {
        margin-left: 10px;
        max-width: 840px;
        max-height: 640px;
        padding: 80px 100px 100px 50px;
    }

    @media (max-width: 916px) {
        margin-left: 10px;
        max-width: 840px;
        max-height: 640px;
        padding: 60px 50px 50px 50px;
    }

    @media (max-width: 866px) {
        margin-left: 0;
    }
    
    @media (max-width: 449px) {
        padding: 60px 10px 50px 10px;
    }
`;

const TagContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;

    @media (max-width: 449px) {
        gap: 10px;
    }
`;

const Heading = styled.h2`
    color: var(--dark-blue);
    font-size: 36px;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 20px;

    @media (max-width: 1281px) {
        margin-bottom: 0;
        margin-top: 0;
    }
`;

const Description = styled.p`
    line-height: 1.6;
    margin-top: 20px;
    color: #636e72;
`;

const ButtonContainer = styled.div`
    display: flex;
    margin-top: 30px;
    align-items: center;
    gap: 20px;

    @media (max-width: 1631px) {
        align-items: start;
        flex-direction: column;
        gap: 10px;
    }

    @media (max-width: 1281px) {
        align-items: start;
        flex-direction: column;
        gap: 10px;
    }
    
    @media (max-width: 991px) {
        flex-direction: column;
        gap: 10px;
    }

    @media (max-width: 866px) {
        flex-direction: row;
        align-items: center;
        gap: 20px;
    }

    @media (max-width: 617px) {
        flex-direction: column;
        align-items: start;
    }
`;
export default AboutMeSection;