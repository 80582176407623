import React from "react";

function GitHubIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <path
                fill="#505663"
                d="M20 0c-4.75 0-9.343 1.733-12.96 4.888C3.423 8.044 1.019 12.415.258 17.222a20.94 20.94 0 002.63 13.9c2.458 4.165 6.282 7.299 10.787 8.839 1 .18 1.375-.436 1.375-.974 0-.487-.025-2.102-.025-3.82C10 36.116 8.7 33.912 8.3 32.758a7.451 7.451 0 00-2.05-2.896c-.7-.385-1.7-1.333-.025-1.359.64.071 1.253.3 1.788.665.536.366.977.859 1.287 1.437.274.504.641.947 1.082 1.305.441.358.947.623 1.488.78a4.173 4.173 0 003.255-.393 4.359 4.359 0 011.275-2.743c-4.45-.512-9.1-2.281-9.1-10.125a8.096 8.096 0 012.05-5.51 7.542 7.542 0 01.2-5.435s1.675-.538 5.5 2.102a18.407 18.407 0 0110 0c3.825-2.666 5.5-2.102 5.5-2.102a7.54 7.54 0 01.2 5.434 8.048 8.048 0 012.05 5.511c0 7.87-4.675 9.613-9.125 10.125a4.873 4.873 0 011.078 1.746c.233.655.325 1.353.272 2.048 0 2.742-.025 4.947-.025 5.639 0 .538.375 1.179 1.375.974 4.497-1.553 8.31-4.694 10.758-8.862a20.952 20.952 0 002.604-13.894c-.766-4.802-3.17-9.168-6.786-12.32C29.335 1.736 24.745.004 20 0z"
            ></path>
        </svg>
    );
}

export default GitHubIcon;