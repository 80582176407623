export const config = {
    urls: {
        linkedin: 'https://www.linkedin.com/in/aleksandrtitov/',
        github: 'https://github.com/0xZonaV/',
        telegram: 'https://t.me/ZonaV_Official',
        resume: 'https://drive.google.com/file/d/1rZRFxsy3CfLQn5RdqDG5mpbIIU3Zm3cH/view?usp=sharing',
        hunter: 'https://airdrop-hunter.site/',
        hamster: 'https://www.youtube.com/watch?v=x6sNZoFu6CY&t=397s&ab_channel=CryptoHamster'
    },
    email: "zonav@zonav.tech",
}